import React from "react";

const colors = {
  maroon: "#701a27", // accent
  blue: "#068D9D", // accent
  green: "#B5B967", // secondary
  platinum: "#E7E3E0", // prim
  white: "#FFFFFF", // prim
};

function Footer() {
  const n = 2021;
  try {
    const d = new Date();
    const n = d.getFullYear();
  } catch (error) {
    console.log(error);
  }

  return (
    <>
      <div
        style={{
          backgroundColor: colors.platinum,
          width: "100%",
          height: "100%",
          textAlign: "center",
        }}
      >
        Arash Abdollahzadeh - {typeof n !== "undefined" ? n : 2021}
      </div>
    </>
  );
}

export default Footer;
